.grid {
	display: grid;
	position: absolute;
	width: 100%;
	height: inherit;
	min-height: inherit;
	top: 0;
	left: 0;
}

.grid-main {
	z-index: 1;
}

.grid-shadow {
	z-index: 0;
}

.grid-1 {
	grid-template-rows: repeat(1, 1fr);
	grid-template-columns: repeat(1, 1fr);
}

.grid-2,
.grid-6 {
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(1, 1fr);
}

.grid-4 {
	grid-template-rows: repeat(4, 1fr);
	grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: $mq-xs) {
	.grid-2,
	.grid-4,
	.grid-6 {
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (min-width: $mq-md) {
	.grid-4,
	.grid-6 {
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: $mq-ml) {
	.grid-2 {
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: repeat(2, 1fr);
	}

	.grid-6 {
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(3, 1fr);
	}
}
