.page--404 #content {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100vh; // tbc
}

.page--404 #content .title {
	margin-bottom: var(--margin-sm);
}
