.page--home #content,
.page--home .kaleidoscope {
	display: block;
	height: inherit;
	min-height: inherit;
}

.kaleidoscope {
	position: relative;
}

.card {
	position: relative;
	overflow-x: hidden;
	overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card {
	-ms-overflow-style: none; // IE and Edge
	scrollbar-width: none; // Firefox
}

/* Hide scrollbar for IE, Edge and Firefox */
/* https://stackoverflow.com/questions/77884029/customize-scrollbar-webkit-scrollbar-not-working-in-version-121-0-6167-86 */
@-moz-document url-prefix() {
	.card {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

/* https://syntackle.com/blog/changes-to-scrollbar-styling-in-chrome-121/ */
@supports not selector(::-webkit-scrollbar) {
	html {
		scrollbar-width: none;
		scrollbar-color: transparent transparent;
	}
}

/** 
 *	card header
 *
 */

.card > .card__header {
	display: block;
	width: 100%;
	position: absolute;
	mix-blend-mode: difference;
	color: lightgrey;
	padding: var(--margin-card);
	top: 0;
	left: 0;
	z-index: 2; // above card caption
}

.card__nav {
	display: block;
	display: flex;
	justify-content: space-between;
}

.grid-main .nav__item.title--small {
	display: block;
}

.grid-main .nav__item.title--large {
	display: none;
}

.grid-main .nav__item.about {
	display: block;
}

.grid-shadow .card__header {
	color: rgba(0, 0, 0, 0.5);
}

.grid-shadow .nav__item .text {
	visibility: hidden;
}

@media screen and (min-width: $mq-md) and (hover: hover) {
	.grid-main .nav__item.title--small {
		display: none;
	}

	.grid-main .nav__item.title--large {
		display: block;
	}

	.grid-main .nav__item.about {
		display: none;
	}

	.grid-main .nav__group .nav__item.title--large,
	.grid-main .nav__group .nav__item.about {
		min-width: 10ch;
	}

	.grid-main .nav__group:hover .nav__item.title--large,
	.grid-main .nav__group.is-hovered .nav__item.title--large {
		display: none;
	}

	.grid-main .nav__group:hover .nav__item.about,
	.grid-main .nav__group.is-hovered .nav__item.about {
		display: block;
	}
}

/** 
 *	card media
 *
 */

.card__media {
	width: 100%;
	max-width: 100vw;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;
	cursor: pointer;
}

.card__media .media {
	width: inherit;
	height: inherit;
	position: relative;
	overflow: hidden;
}

.card__media .media > * {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	object-position: center;
	position: absolute;
}

.grid-2 {
	.card-1 .card__media .media > * {
		top: 0;
		left: 0;
	}

	.card-2 .card__media .media > * {
		top: 0;
		left: unset;
		bottom: unset;
		right: 0;
	}
}

.grid-4 {
	.card-1 .card__media .media > * {
		top: 0;
		left: 0;
	}

	.card-2 .card__media .media > * {
		top: 0;
		left: unset;
		bottom: unset;
		right: 0;
	}

	.card-3 .card__media .media > * {
		top: unset;
		left: 0;
		bottom: 0;
		right: unset;
	}

	.card-4 .card__media .media > * {
		top: unset;
		left: unset;
		bottom: 0;
		right: 0;
	}
}

.grid-6 {
	.card-1 .card__media .media > * {
		top: 0;
		left: 0;
	}

	.card-2 .card__media .media > * {
		top: 0;
		left: 50%;
		bottom: unset;
		right: unset;
		transform: translateX(-50%);
	}

	.card-3 .card__media .media > * {
		top: 0;
		left: unset;
		bottom: unset;
		right: 0;
	}

	.card-4 .card__media .media > * {
		top: unset;
		left: 0;
		bottom: 0;
		right: unset;
	}
}

@media screen and (min-width: $mq-ml) {
	.grid-6 {
		.card-1 .card__media .media > * {
			top: 0;
			left: 0;
		}

		.card-2 .card__media .media > * {
			top: 0;
			left: 50%;
			bottom: unset;
			right: unset;
			transform: translateX(-50%);
		}

		.card-3 .card__media .media > * {
			top: 0;
			left: unset;
			bottom: unset;
			right: 0;
		}

		.card-4 .card__media .media > * {
			top: unset;
			left: 0;
			bottom: 0;
			right: unset;
		}

		.card-5 .card__media .media > * {
			top: unset;
			left: 50%;
			bottom: 0;
			right: unset;
			transform: translateX(-50%);
		}

		.card-6 .card__media .media > * {
			top: unset;
			left: unset;
			bottom: 0;
			right: 0;
		}
	}
}

/** 
 *	card info caption
 *
 */

.card__caption {
	display: none;
	width: 100%;
	text-align: center;
	position: absolute;
	mix-blend-mode: difference;
	color: lightgrey;
	padding: var(--margin-card);
	bottom: 0;
	left: 0;
	z-index: 1; // below card header
}

.grid.has-caption .card__caption {
	display: block;
}

.caption-state-on,
.caption-state-off {
	width: 2ch;
}

.caption-state-on {
	display: none;
}

.grid.has-caption .caption-state-on {
	display: inline-block;
}

.caption-state-off {
	display: inline-block;
}

.grid.has-caption .caption-state-off {
	display: none;
}

/** 
 *	card about
 *
 */

.card__about {
	height: 100%;
	display: none;
	position: absolute;
	mix-blend-mode: difference;
	color: lightgrey;
	overflow-y: scroll;
	padding: var(--margin-card);
	top: 0;
	left: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card__about::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card__about {
	-ms-overflow-style: none; // IE and Edge
	scrollbar-width: none; // Firefox
}

/* Hide scrollbar for IE, Edge and Firefox */
/* https://stackoverflow.com/questions/77884029/customize-scrollbar-webkit-scrollbar-not-working-in-version-121-0-6167-86 */
@-moz-document url-prefix() {
	.card__about {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

.grid.is-about .card > .card__header,
.grid.is-about .card > .card__caption {
	display: none;
}

.grid.is-about .card__about {
	display: block;
}

.card__about .close {
	display: inline-block;
	padding-bottom: 1em;
}

.card__about .about__text > p,
.card__about p + p {
	display: block;
	padding-bottom: 1em;
}

.card__about ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 1em;
}

.card__about p strong {
	font-weight: normal;
	margin-left: 4rem;
}

.card__about a:hover {
	color: mediumspringgreen;
}

.card__about .credits {
	margin-top: 8rem;
}
