html {
	font-family: var(--font);
	font-size: var(--font-size, 12px);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.grid .card {
	font-size: 16px;
}

.grid-4 .card {
	font-size: 12px;
}

@media screen and (min-width: $mq-xs) {
	.grid .card {
		font-size: 2rem;
	}
}

@media screen and (min-width: $mq-md) {
	.grid .card {
		font-size: 1.5rem;
	}

	.grid-1 .card,
	.grid-2 .card {
		font-size: 2rem;
	}
}

@media screen and (min-width: $mq-lg) {
	.grid-1 .card {
		// font-size: 3.75rem;
		font-size: 4rem;
	}

	.grid-2 .card {
		font-size: 2.5rem;
	}

	.grid-4 .card {
		font-size: 2rem;
	}

	.grid-6 .card {
		font-size: 1.5rem;
	}
}
