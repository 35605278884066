:root {
	--font: 'bianca';
	--font-size: 12px;
	--margin-sm: 0.5rem;
	--margin-card: 2rem 2.5rem 1.5rem;
	--color-bg: rgb(208, 198, 186);
	--default-duration: 0.4s;
	--default-ease: ease-in-out;
}

/* Query based on tailwind css */
/* https://tailwindcss.com/docs/responsive-design */
$mq-xs: 480px;
$mq-sm: 640px;
$mq-md: 768px;
$mq-ml: 992px;
$mq-lg: 1024px;
$mq-xl: 1280px;
$mq-xxl: 1536px;
