.page--home .grid {
	opacity: 0;
	transition-property: opacity;
	transition-timing-function: ease-in-out;
	transition-duration: 400ms;
	transition-delay: 0ms;
}

.page--home .grid-shadow.is-visible,
.page--home .grid-main.is-visible {
	opacity: 1;
}
