.main {
	height: 100%; // fallback
	height: 100vh; // fallback
	height: 100svh;
	min-height: 100vh !important; // fallback
	min-height: 100svh !important;
}

.page {
	height: inherit;
	min-height: inherit;
	display: block;
}

.page-size-warning {
	display: none;
}

/* below this screen size, hide the site */
@media screen and (max-width: 300px) {
	.page {
		display: none;
	}

	.page-size-warning {
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}

	.page-size-warning > * {
		max-width: 300px;
		min-width: 250px;
		text-align: center;
	}
}
