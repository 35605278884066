:root {
  --font: "bianca";
  --font-size: 12px;
  --margin-sm: .5rem;
  --margin-card: 2rem 2.5rem 1.5rem;
  --color-bg: #d0c6ba;
  --default-duration: .4s;
  --default-ease: ease-in-out;
}

*, :after, :before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  color: #00fa9a;
  background-color: rgba(255, 255, 255, .5);
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg);
  box-sizing: inherit;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

button {
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  color: inherit;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0;
  padding: 0;
  display: block;
}

a, a:hover, a:visited {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3 {
  margin: 0;
  font-weight: normal;
  line-height: 1.25;
}

p {
  margin: 0;
  line-height: 1.25;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.main {
  height: 100vh;
  height: 100svh;
  min-height: 100vh !important;
  min-height: 100svh !important;
}

.page {
  height: inherit;
  min-height: inherit;
  display: block;
}

.page-size-warning {
  display: none;
}

@media screen and (max-width: 300px) {
  .page {
    display: none;
  }

  .page-size-warning {
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100vh;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .page-size-warning > * {
    text-align: center;
    min-width: 250px;
    max-width: 300px;
  }
}

.grid {
  height: inherit;
  min-height: inherit;
  width: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-main {
  z-index: 1;
}

.grid-shadow {
  z-index: 0;
}

.grid-1 {
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
}

.grid-2, .grid-6 {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(1, 1fr);
}

.grid-4 {
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 480px) {
  .grid-2, .grid-4, .grid-6 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .grid-4, .grid-6 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .grid-2 {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-6 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
}

.page--home .grid {
  opacity: 0;
  transition: opacity .4s ease-in-out;
}

.page--home .grid-shadow.is-visible, .page--home .grid-main.is-visible {
  opacity: 1;
}

html {
  font-family: var(--font);
  font-size: var(--font-size, 12px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grid .card {
  font-size: 16px;
}

.grid-4 .card {
  font-size: 12px;
}

@media screen and (min-width: 480px) {
  .grid .card {
    font-size: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .grid .card {
    font-size: 1.5rem;
  }

  .grid-1 .card, .grid-2 .card {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .grid-1 .card {
    font-size: 4rem;
  }

  .grid-2 .card {
    font-size: 2.5rem;
  }

  .grid-4 .card {
    font-size: 2rem;
  }

  .grid-6 .card {
    font-size: 1.5rem;
  }
}

.page--home #content, .page--home .kaleidoscope {
  height: inherit;
  min-height: inherit;
  display: block;
}

.kaleidoscope {
  position: relative;
}

.card {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.card::-webkit-scrollbar {
  display: none;
}

.card {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@-moz-document url-prefix() {
  .card {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
}

.card > .card__header {
  mix-blend-mode: difference;
  color: #d3d3d3;
  padding: var(--margin-card);
  z-index: 2;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.card__nav {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.grid-main .nav__item.title--small {
  display: block;
}

.grid-main .nav__item.title--large {
  display: none;
}

.grid-main .nav__item.about {
  display: block;
}

.grid-shadow .card__header {
  color: rgba(0, 0, 0, .5);
}

.grid-shadow .nav__item .text {
  visibility: hidden;
}

@media screen and (min-width: 768px) and (hover: hover) {
  .grid-main .nav__item.title--small {
    display: none;
  }

  .grid-main .nav__item.title--large {
    display: block;
  }

  .grid-main .nav__item.about {
    display: none;
  }

  .grid-main .nav__group .nav__item.title--large, .grid-main .nav__group .nav__item.about {
    min-width: 10ch;
  }

  .grid-main .nav__group:hover .nav__item.title--large, .grid-main .nav__group.is-hovered .nav__item.title--large {
    display: none;
  }

  .grid-main .nav__group:hover .nav__item.about, .grid-main .nav__group.is-hovered .nav__item.about {
    display: block;
  }
}

.card__media {
  cursor: pointer;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.card__media .media {
  width: inherit;
  height: inherit;
  position: relative;
  overflow: hidden;
}

.card__media .media > * {
  object-fit: cover;
  object-position: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.grid-2 .card-1 .card__media .media > * {
  top: 0;
  left: 0;
}

.grid-2 .card-2 .card__media .media > * {
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
}

.grid-4 .card-1 .card__media .media > * {
  top: 0;
  left: 0;
}

.grid-4 .card-2 .card__media .media > * {
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
}

.grid-4 .card-3 .card__media .media > * {
  top: unset;
  bottom: 0;
  left: 0;
  right: unset;
}

.grid-4 .card-4 .card__media .media > * {
  top: unset;
  left: unset;
  bottom: 0;
  right: 0;
}

.grid-6 .card-1 .card__media .media > * {
  top: 0;
  left: 0;
}

.grid-6 .card-2 .card__media .media > * {
  top: 0;
  left: 50%;
  bottom: unset;
  right: unset;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.grid-6 .card-3 .card__media .media > * {
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
}

.grid-6 .card-4 .card__media .media > * {
  top: unset;
  bottom: 0;
  left: 0;
  right: unset;
}

@media screen and (min-width: 992px) {
  .grid-6 .card-1 .card__media .media > * {
    top: 0;
    left: 0;
  }

  .grid-6 .card-2 .card__media .media > * {
    top: 0;
    left: 50%;
    bottom: unset;
    right: unset;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .grid-6 .card-3 .card__media .media > * {
    top: 0;
    left: unset;
    bottom: unset;
    right: 0;
  }

  .grid-6 .card-4 .card__media .media > * {
    top: unset;
    bottom: 0;
    left: 0;
    right: unset;
  }

  .grid-6 .card-5 .card__media .media > * {
    top: unset;
    bottom: 0;
    left: 50%;
    right: unset;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .grid-6 .card-6 .card__media .media > * {
    top: unset;
    left: unset;
    bottom: 0;
    right: 0;
  }
}

.card__caption {
  text-align: center;
  mix-blend-mode: difference;
  color: #d3d3d3;
  padding: var(--margin-card);
  z-index: 1;
  width: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.grid.has-caption .card__caption {
  display: block;
}

.caption-state-on, .caption-state-off {
  width: 2ch;
}

.caption-state-on {
  display: none;
}

.grid.has-caption .caption-state-on, .caption-state-off {
  display: inline-block;
}

.grid.has-caption .caption-state-off {
  display: none;
}

.card__about {
  mix-blend-mode: difference;
  color: #d3d3d3;
  padding: var(--margin-card);
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.card__about::-webkit-scrollbar {
  display: none;
}

.card__about {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@-moz-document url-prefix() {
  .card__about {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.grid.is-about .card > .card__header, .grid.is-about .card > .card__caption {
  display: none;
}

.grid.is-about .card__about {
  display: block;
}

.card__about .close {
  padding-bottom: 1em;
  display: inline-block;
}

.card__about .about__text > p, .card__about p + p {
  padding-bottom: 1em;
  display: block;
}

.card__about ul {
  margin: 0 0 1em;
  padding: 0;
  list-style: none;
}

.card__about p strong {
  margin-left: 4rem;
  font-weight: normal;
}

.card__about a:hover {
  color: #00fa9a;
}

.card__about .credits {
  margin-top: 8rem;
}

.page--404 #content {
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.page--404 #content .title {
  margin-bottom: var(--margin-sm);
}

/*# sourceMappingURL=app.css.map */
