*,
:after,
:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*,
:after,
:before {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/*
*:focus,
*:focus-within {
	background-color: transparent;
	box-shadow: none;
	outline: none;
	border: none;
}
*/

// no-select !
::selection {
	background-color: rgba(255, 255, 255, 0.5);
	color: mediumspringgreen;
}

html {
	box-sizing: border-box;
}

body {
	background-color: var(--color-bg);
	overflow: hidden;
	box-sizing: inherit;
	max-width: 100vw;
	margin: 0;
	padding: 0;
}

button {
	display: block;
	font-family: inherit;
	font-size: inherit;
	text-align: left;
	background-color: transparent;
	border: none;
	color: inherit;
	cursor: pointer;
	padding: 0;
	margin: 0;
}

a,
a:hover,
a:visited {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3 {
	margin: 0;
	line-height: 1.25;
	font-weight: normal;
}

p {
	margin: 0;
	line-height: 1.25;
}

img {
	user-select: none;
	display: block;
}
